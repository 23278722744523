<script>
import feather from 'feather-icons';

export default {
	name: 'Home',
	data: () => {
		return {
			theme: '',
		};
	},
	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
		feather.replace();
		this.theme = localStorage.getItem('theme') || 'light';
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

<template>
	<section>
		<div class="flex justify-center pt-10 sm:pt-20">
			<h1
				class="font-general-semibold text-3xl md:text-3xl xl:text-4xl text-center sm:text-left text-ternary-dark dark:text-primary-light uppercase">
				Ventilatsioonid on just meie tugevus
			</h1>
		</div>
		<div class="flex justify-center">
			<p
				class="font-general-medium mt-2 text-lg sm:text-xl  xl:text-2xl text-center sm:text-left leading-none text-gray-400">
				Toetume oma kogemusele ja lähtume teie vajadusest
			</p>
		</div>
		<!--
		<div class="flex justify-center">
			<a
				download="Stoman-Resume.pdf"
				href="/files/pakkumine.pdf"
				class="flex justify-center items-center w-36 sm:w-48 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-indigo-500 text-gray-500 hover:text-white duration-500"
				aria-label="Download Resume"
			>
				<span
					class="text-sm sm:text-lg font-general-medium duration-100"
					>Näidispakkumine</span
				></a
			>
		</div>
	-->
		<div class="flex justify-center pt-20">
			<img v-if="theme === 'light'" src="@/assets/images/undraw_city_life_gnpr-svg.svg" alt="Developer" />
			<img v-else src="@/assets/images/undraw_city_life_gnpr-dark.svg" alt="Developer" />
		</div>
	</section>

	<!--
	<section
		class="flex flex-col sm:justify-between items-center sm:flex-row mt-12 sm:mt-10"
	>
		<div class="w-full md:w-1/3 text-left">
			<h1
				class="font-general-semibold text-3xl md:text-3xl xl:text-4xl text-center sm:text-left text-ternary-dark dark:text-primary-light uppercase"
			>
				Ventilatsioonid on meie tugevus
			</h1>
			<p
				class="font-general-medium mt-2 text-lg sm:text-xl  xl:text-2xl text-center sm:text-left leading-none text-gray-400"
			>
				Paigaldame just teile sobiva ventilatsiooni
			</p>
			<div class="flex justify-center sm:block">
				<a
					download="Stoman-Resume.pdf"
					href="/files/Stoman-Resume.pdf"
					class="flex justify-center items-center w-36 sm:w-48 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-indigo-500 text-gray-500 hover:text-white duration-500"
					aria-label="Download Resume"
				>
					<span
						class="text-sm sm:text-lg font-general-medium duration-100"
						>Näidispakkumine</span
					></a
				>
			</div>
		</div>
		<div class="w-full md:w-2/3 text-right float-right">
			<img
				v-if="theme === 'light'"
				src="@/assets/images/undraw_city_life_gnpr.svg"
				alt="Developer"
			/>
			<img
				v-else
				src="@/assets/images/undraw_city_life_gnpr.svg"
				alt="Developer"
			/>
		</div>
	</section>
	-->
</template>

<style scoped>

</style>
