// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
	{
		id: 1,
		title: 'Eramaja ventilatsiooni paigaldus',
		category: 'Ventilatsioonid',
		img: require('@/assets/images/eramaja_vent/eramaja_vent1.jpeg'),
	},
	{
		id: 2,
		title: 'Eramaja soojustagastusega ventilatsioon',
		category: 'Ventilatsioonid',
		img: require('@/assets/images/eramaja_soojustagastus/eramaja_soojustagastus1.jpeg'),
	},
	{
		id: 3,
		title: 'Õhksoojuspump eramajale',
		category: 'Õhk-õhk soojuspumbad',
		img: require('@/assets/images/ohksoojuspump_eramaja/eramaja_soojuspump1.jpeg'),
	},
	{
		id: 4,
		title: 'Suvila soojuspumba paigaldus',
		category: 'Õhk-õhk soojuspumbad',
		img: require('@/assets/images/suvila_soojuspump/suvila_ohksoojuspump1.jpeg'),
	},
	{
		id: 5,
		title: 'Konditsioneeri valmiduse paigaldus',
		category: 'Konditsioneerid',
		img: require('@/assets/images/kont_valmidus/kont_valmidus1.jpeg'),
	},
	{
		id: 6,
		title: 'Lahendsime kogu maja jahutus vajaduse',
		category: 'Konditsioneerid',
		img: require('@/assets/images/kogumaja_jahutus/kogumaja1.jpeg'),
	},
];

export default projects;
